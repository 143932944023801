import React from 'react'
import { SbBlokData, storyblokEditable } from '@storyblok/react'
import ItemSectionHeadV2 from '@/v2/storyblok/items/ItemSectionHeadV2'
import classNames from 'classnames'

type Props = {
  blok: SbBlokData & ISbSectionTextColumnsV2
}

const SectionTextColumnsV2 = ({ blok }: Props) => {
  const isBackgroundDark = blok.background === 'green-dark'
  const textColor = isBackgroundDark
    ? '[&_p]:text-white [&_h2]:text-white [&_h3]:text-white [&_span]:text-white'
    : 'text-v2-text-primary'
  const background = blok.background ? `bg-v2-background-${blok.background}` : 'bg-v2-background-white'
  const hasFourColumns = blok.columns.length === 4

  return (
    <div className={classNames(['py-16 lg:py-24', textColor, background])} {...storyblokEditable(blok)}>
      <div className="container-v2 mx-auto grid gap-14 md:gap-16">
        {!!blok.head?.length && (
          <div className="md:px-10">
            <ItemSectionHeadV2 blok={blok.head[0]} isDark={isBackgroundDark} />
          </div>
        )}
        <div className={`grid flex-nowrap md:flex-row lg:gap-8 lg:grid-cols-${blok.columns.length}`}>
          {Array.isArray(blok.columns) &&
            blok.columns.map((row, index) => (
              <div key={index} {...storyblokEditable(row)}>
                <div
                  className={classNames([
                    'grid h-full gap-6 p-8 md:p-10',
                    isBackgroundDark ? 'border-v2-background-white' : 'border-v2-foreground-static-tertiary',
                    { 'border-t-2 lg:border-0 lg:border-l-2 lg:!pl-14': index },
                  ])}
                >
                  <h3 className={['md:whitespace-pre-line', hasFourColumns ? 'heading-5' : 'heading-3'].join(' ')}>
                    {row.title}
                  </h3>
                  <p
                    className={['whitespace-pre-line text-v2-text-primary', hasFourColumns ? 'body-2' : 'body-1-bold'].join(
                      ' ',
                    )}
                  >
                    {row.text}
                  </p>
                </div>
              </div>
            ))}
        </div>
      </div>
    </div>
  )
}

export default SectionTextColumnsV2
